<template>
  <div>
    <div class="filter-content">
      <el-row :gutter="20">
        <el-col :span="4">
          <el-input
            size="small"
            v-model="queryParams.art_no"
            placeholder="货号"
          ></el-input>
        </el-col>
        <el-col :span="4">
          <el-input
            size="small"
            v-model="queryParams.product_name"
            placeholder="中英文名称"
          ></el-input>
        </el-col>
        <el-col :span="6">
          <el-date-picker
            v-model="queryParams.times"
            size="small"
            type="daterange"
            :clearable="true"
            format="yyyy-MM-dd"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :unlink-panels="true"
          >
          </el-date-picker>
        </el-col>
        <el-col :span="5" :offset="5">
          <el-button type="default" class="fr" size="small" @click="reset"
            >重置</el-button
          >
          <el-button
            type="primary"
            class="fr"
            size="small"
            @click="search"
            style="margin-right: 12px"
            >查询</el-button
          >
        </el-col>
      </el-row>
    </div>
    <div class="table-content tableBlock" v-loading="loading">
      <el-table
        :data="tableData"
        :height="tableHeight"
        v-tableHeight="{ bottomOffset: 74 }"
        style="width: 100%"
        border
      >
        <el-table-column label="公司全称" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{
            scope.row.company_name || "-"
          }}</template>
        </el-table-column>
        <el-table-column label="货号" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span class="artno" @click="openPreDesc(scope.row)">
              {{ scope.row.art_no || "-" }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="中文名称" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{
            scope.row.product_name_zh || "-"
          }}</template>
        </el-table-column>
        <el-table-column label="英文名称" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{
            scope.row.product_name_en || "-"
          }}</template>
        </el-table-column>
        <el-table-column label="申请时间">
          <template slot-scope="scope">{{
            scope.row.apply_time | secondFormat
          }}</template>
        </el-table-column>
        <el-table-column label="支付状态" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.pay_status | dataFormat }}
          </template>
        </el-table-column>
        <el-table-column label="审核状态">
          <template slot-scope="scope">
            <span v-if="scope.row.status">{{ scope.row.audit_status | auditStatusFormat }}</span><span v-else>草稿</span>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <span>
              <template v-if="scope.row.audit_status != 1">
                <el-button
                  v-if="scope.row.pay_status != 1 && scope.row.status"
                  type="text"
                  size="small"
                  @click="prePay(scope.row)"
                  >支付</el-button
                >
              </template>
              <el-button
                v-if="scope.row.audit_status != 1"
                type="text"
                size="small"
                @click="openPreDesc(scope.row, 'edit')"
                >编辑</el-button
              >
              <template v-if="scope.row.pay_status != 1">
                <el-button
                  v-if="scope.row.audit_status != 1"
                  type="text"
                  size="small"
                  @click="deletePRE(scope.row)"
                  >删除</el-button
                >
              </template>
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer-content paginationBlock">
      <el-pagination
        layout="total, prev, pager, next"
        :total="total"
        :current-page.sync="currentPage"
        :page-size="15"
      ></el-pagination>
    </div>

    <!-- 审核预归类dialog -->
    <el-dialog
      :title="preDialogTitle"
      :visible.sync="preDescription"
      v-if="preDescription"
      width="1060px"
      v-loading="dialogBtnLoading"
    >
      <div class="pre-text" v-if="dynamicDetail">
        <preClassification
          :showTitle="false"
          :isEdit="isEdit"
          :disabled="disabled"
          :isApproved="isApproved"
          :form_data="dynamicDetail"
          @startUploadOSS="startUploadOSS"
          @uploadCompleteOSS="uploadCompleteOSS"
        ></preClassification>
      </div>
    </el-dialog>
    <pre-classPay :pay_bol="pay_bol" :code_loading="code_loading" :pay_code="pay_code" @closePay="closePay" @codeStop="codeStop" @codeUpdate="codeUpdate"/>
  </div>
</template>

<script>
import preClassification from "~scb/components/form/preClassification";
import preClassPay from "~scb/components/pay/preClassPay"
import { preClassificationMoneyFormat } from "~scb/plugins/filters"
export default {
  props: {},
  data() {
    return {
      currentPage: 1,
      applyForDialog: false,
      showVideoDialog: false,
      loading: true,
      dialogBtnLoading: false,
      disabled: true,
      isEdit: false,
      total: 0,
      dynamicDetail: null,
      preDescription: false,
      tableHeight: 100,
      tableData: [],
      preDialogTitle: "",
      queryParams: {
        art_no: "",
        product_name: "",
      },
      examineFormData: {
        recommended_ein: "",
        declare_elements: "",
        classified_reason: "",
      },
      rules: {
        recommended_ein: [{ required: true, message: " ", trigger: "blur" }],
        declare_elements: [{ required: true, message: " ", trigger: "blur" }],
        classified_reason: [
          { required: true, message: " ", trigger: "change" },
        ],
      },
      pay_code: {},//支付信息
      isApproved: false,
      pay_bol: false,//支付弹窗
      timeout_set: '',//延时器
      pay_form: {},//支付参数
      code_loading: false,//二维码
      pay_item: '',//当前支付订单数据
    };
  },

  computed: {},
  created() {},
  mounted() {
    this.getList();
  },
  watch: {
    currentPage() {
      this.getList();
    },
  },
  methods: {
    // 开始上传
    startUploadOSS() {
      this.dialogBtnLoading = true;
    },
    // 上传完成
    async uploadCompleteOSS(value) {
      this.dialogBtnLoading = true;
      value.source = this.PJSource;
      value.classification_id = this.dynamicDetail.id;
      value.product_image = JSON.stringify(value.product_image);
      let res = await this.$store.dispatch(
        "baseConsole/editPreClassificationInfo",
        value
      );
      if (res.success) {
        this.$message({
          message: "编辑成功",
          type: "success",
        });
        this.preDescription = false;
        this.getList();
      }
      this.dialogBtnLoading = false;
    },
    applyForExamine() {
      this.$refs["examineFormDataRef"].validate(async (valid) => {
        if (valid) {
          let params = {};
          params.classification_id = this.currentApplyForId;
          params = Object.assign(params, this.examineFormData);
          pre_classification_examine(params).then((res) => {
            if (res.success) {
              this.$message({
                message: "审核成功",
                type: "success",
              });
              this.$refs["examineFormDataRef"].resetFields();
              this.getList();
            }
            this.applyForDialog = false;
          });
        }
      });
    },
    async openPreDesc(item, type) {
      if (type == "edit") {
        this.preDialogTitle = "编辑预归类";
        this.disabled = false;
        this.isEdit = true;
      } else {
        this.preDialogTitle = "查看预归类";
        this.disabled = true;
        this.isEdit = false;
      }
      this.preDescription = true;
      this.dynamicDetail = null;
      const res = await this.$store.dispatch(
        "baseConsole/getPreClassificationInfo",
        { classification_id: item.id, source: this.PJSource, status: 8 }
      );
      if (res.success) {
        this.dynamicDetail = res.data;
        if(res.data.audit_status == 1){
          this.isApproved = true;
        }else{
          this.isApproved = false;
        }
      }
    },
    deletePRE(item) {
      this.$confirm("确认删除该数据？", "删除", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {};
          params.classification_id = item.id;
          params.source = this.PJSource;
          params.deleted = 1;
          this.$store
            .dispatch("baseConsole/editPreClassificationInfo", params)
            .then((res) => {
              if (res.success) {
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
                this.getList();
              }
            });
        })
        .catch(() => {});
    },
    reset() {
      let _params = Object.assign({}, this.queryParams);
      Object.keys(_params).forEach((item) => {
        _params[item] = null;
      });
      this.queryParams = _params;
    },
    search() {
      this.currentPage = 1;
      this.getList();
    },
    async prePay(row){
      try {
        this.loading = true;
        this.pay_item = row;
        await this.examinePre();
        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },
    // 生成支付码
    async examinePre() {
      this.pay_form = {
        goods_type: "3",
        payment: "1",
        source: this.PJSource,
        user_id: this.USER_INFO.id,
        related_id: this.pay_item.id,
        belongto: "1",
        goods_amount: "1",
        goods_belongto: "1",
        user_name: this.USER_INFO.name_zh || this.USER_INFO.name_en,
        company_id: this.USER_INFO.company_id,
        company_name: this.USER_INFO.company_name_zh || this.USER_INFO.company_name_en,
      }
      try {
        await this.getPayCheckOrder();
        let res = await this.$store.dispatch("API_company/payCourse",this.pay_form);
        if (res.success) {
          this.pay_code = res.data;
          this.pay_bol = true;
          this.payStatus();
        }
      } catch (err) {}
    },
    // 二维码失效
    codeStop(){
      clearTimeout(this.timeout_set);
    },
    // 更新二维码
    async codeUpdate(){
      try {
        this.code_loading = true;
        await this.examinePre();
        this.code_loading = false;
      } catch (err) {
        this.code_loading = false;
      }
    },
    // 获取历史订单
    async getPayCheckOrder(){
      let res = await this.$store.dispatch(
          "API_index/payCheckOrder",
          { 
            company_id: this.USER_INFO.company_id,
            user_id: this.USER_INFO.id
          }
      );
      if (res.success) {
          this.pay_form.amount_total = preClassificationMoneyFormat(res.buy_count);
          this.pay_form.goods_price = preClassificationMoneyFormat(res.buy_count);
          this.pay_form.amount_paid = preClassificationMoneyFormat(res.buy_count,this.USER_INFO.aila_no ? true : false);
      }
    },
    // 支付状态轮训
    payStatus(){
      this.timeout_set = setTimeout(() => {
        let {related_id, user_id} = this.pay_form;
        this.$store.dispatch("API_index/payClassCheckOrder",{
          user_id,
          related_id,
          goods_type: 3
        }).then(res=>{
          if (res.success) {
            if (res.data.status == 1) {
              clearTimeout(this.timeout_set);
              this.pay_bol = false;
              this.$message({
                message: "支付成功",
                type: "success",
              });
              this.getList();
              return
            }
          }
          this.payStatus();
        }).catch(err=>{
          this.payStatus();
        });
      }, 5000);
    },
    // 取消支付
    closePay(){
      clearTimeout(this.timeout_set);
      this.pay_bol = false;
      this.getList();
    },
    async getList() {
      this.loading = true;
      let params = {
        art_no: this.queryParams.art_no,
        product_name: this.queryParams.product_name,
        start: this.currentPage - 1,
        limit: 15,
        nopage: 0,
        source: this.PJSource,
        company_id: this.USER_INFO.company_id,
        status: 8
      };
      if (this.queryParams.times) {
        params.start_time = this.$moment(this.queryParams.times[0]).unix();
        params.end_time =
          this.$moment(this.queryParams.times[1]).unix() + 86399;
      }
      let res = await this.$store.dispatch(
        "baseConsole/getPreClassificationList",
        params
      );
      if (res.success) {
        if (res.data) {
          res.data.forEach((item) => {
            if (item.content) {
              item.content = JSON.parse(item.content);
            }
          });
        }
        this.tableData = res.data;
        this.total = res.count;
      }

      this.loading = false;
    },
  },

  components: {
    preClassification,
    preClassPay
  },
  filters: {
    dataFormat(value) {
      if (value == "0") {
        value = "未支付";
      } else if (value == 1) {
        value = "已支付";
      } else if (value == 2) {
        value = "已取消";
      }
      return value;
    },
    auditStatusFormat(val) {
      if (val == 0) {
        val = "未审核";
      } else if (val == 1) {
        val = "审核通过";
      } else if (val == 2) {
        val = "审核失败";
      }
      return val;
    },
  },
  beforeDestroy() {
    clearTimeout(this.timeout_set);
  },
};
</script>

<style scoped lang="less">
.artno {
  cursor: pointer;
  color: #0083f6;
}
/deep/.el-form-item__label {
  font-size: 14px !important;
}
/deep/.el-input__inner {
  font-size: 14px !important;
}
/deep/.el-textarea__inner {
  font-size: 14px !important;
}
.pre-classification {
  font-size: 16px;
  width: 1000px !important;
  background: #ffffff;
  padding: 10px 30px !important;
}
.filter-content {
  padding: 24px 12px;
  max-height: 80px;
}
.el-form-item {
}
</style>
