export const creditClass = [           //信用等级
    {
        value: 1,
        label_zh: 'AAA',
        label_en: ''
    },
    {
        value: 2,
        label_zh: 'AA',
        label_en: ''
    },
    {
        value: 3,
        label_zh: 'A',
        label_en: ''
    }
]

export const unitType = [           //单位类型
    {
        value: 5,
        label_zh: '会长单位',
        label_en: ''
    },
    {
        value: 4,
        label_zh: "副会长单位",
        label_en: ''
    },
    {
        value: 3,
        label_zh: '监事单位',
        label_en: ''
    },
    {
        value: 2,
        label_zh: '理事单位',
        label_en: ''
    },
    {
        value: 1,
        label_zh: '会员单位',
        label_en: ''
    },
    {
        value: 21,
        label_zh: '常务理事单位',
        label_en: ''
    }
]
export const cityList = [
    { id: 1, country_id: 1, name_zh: "香港", name_en: "Hong Kong" },
    { id: 2, country_id: 1, name_zh: "澳门", name_en: "Macao" },
    { id: 3, country_id: 1, name_zh: "北京", name_en: "Beijing" },
    { id: 4, country_id: 1, name_zh: "天津", name_en: "Tianjin" },
    { id: 5, country_id: 1, name_zh: "石家庄", name_en: "Shijiazhuang" },
    { id: 6, country_id: 1, name_zh: "唐山", name_en: "Tangshan" },
    { id: 7, country_id: 1, name_zh: "秦皇岛", name_en: "Qinhuangdao" },
    { id: 8, country_id: 1, name_zh: "邯郸", name_en: "Handan" },
]
export const yearList = [           //单位类型
    {
        value: 1,
        label_zh: '2020年',
        label_en: ''
    },
    {
        value: 2,
        label_zh: '2019年',
        label_en: ''
    },
    {
        value: 3,
        label_zh: '2018年',
        label_en: ''
    },
    {
        value: 4,
        label_zh: '2017年',
        label_en: ''
    },
    {
        value: 5,
        label_zh: '2016年',
        label_en: ''
    },
    {
        value: 6,
        label_zh: '2015年',
        label_en: ''
    },
    {
        value: 7,
        label_zh: '2014年',
        label_en: ''
    }
]
export const staffsizeList = [ //企业规模
    {
        value: 1,
        label_en: "1-50 people",
        label_zh: "1-50人"
    },
    {
        value: 2,
        label_en: "51-100 people",
        label_zh: "51-100人"
    },
    {
        value: 3,
        label_en: "101-200 people",
        label_zh: "101-200人"
    },
    {
        value: 4,
        label_en: "201-500 people",
        label_zh: "201-500人"
    },
    {
        value: 5,
        label_en: "501-1000 people",
        label_zh: "501-1000人"
    },
    {
        value: 6,
        label_en: "More than 1001 people",
        label_zh: "1001人及以上"
    }
]

export const setYears = [ //成立年限
    {
        value: '6',
        label_en: "1-5years",
        label_zh: "1-5年"
    },
    {
        value: '7',
        label_en: "6-10 years",
        label_zh: "6-10年"
    },
    {
        value: '5',
        label_en: "more than 10 years",
        label_zh: "10年以上"
    }
]
export const orderStatus = [            //商品类型
    { value: 0, label_zh: '未付款', label_en: '' },
    { value: 1, label_zh: '已付款', label_en: '' },
    { value: 2, label_zh: '已取消', label_en: '' },
    { value: 3, label_zh: '已关闭', label_en: '' },
    { value: 4, label_zh: '已退款', label_en: '' },
]

export const paymentMethod = [              //支付方式
    { value: 1, label_zh: '微信支付', label_en: '' },
    { value: 2, label_zh: '支付宝支付', label_en: '' },
    { value: 3, label_zh: 'paypal支付', label_en: '' },
    { value: 4, label_zh: '线下支付', label_en: '' },
    // { value: 5, label_zh: '条数支付', label_en: '' },
    // { value: 6, label_zh: '积分支付', label_en: '' },
]

export const preClassificationMoney = [            //预归类金额  
    { number:50, money: 60000, member_discount: 0.9 },          //6
    { number:100, money: 50000, member_discount: 0.9 },         //5
    { number:200, money: 40000, member_discount: 0.9 },         //4
    { number:Infinity, money: 30000, member_discount: 0.9 }     //3
]

export const companyTypeList = [            //公司类型
    {
        value: 1,
        label_zh: "一般认证"
    },
    {
        value: 2,
        label_zh: "AEO高级认证"
    },
    {
        value: 3,
        label_zh: "其他"
    },
]

export const companyNatureList = [            //公司性质
    {
        value: 1,
        label_zh: "国有企业"
    },
    {
        value: 2,
        label_zh: "集体所有制"
    },
    {
        value: 3,
        label_zh: "私营企业"
    },
    {
        value: 4,
        label_zh: "股份制企业"
    },
    {
        value: 5,
        label_zh: "有限合伙企业"
    },
    {
        value: 6,
        label_zh: "联营企业"
    },
    {
        value: 7,
        label_zh: "外商投资企业"
    },
    {
        value: 8,
        label_zh: "个人独资企业"
    },
]

export const customsClearanceWayList = [            //报关方式
    {
        value: 1,
        label_zh: "报关企业"
    },
    {
        value: 2,
        label_zh: "收发货人"
    },
    {
        value: 3,
        label_zh: "货代"
    },
    {
        value: 4,
        label_zh: "其他"
    },
]

export const companyScaleList = [            //企业规模
    {
        value: 1,
        label_zh: "特大型企业"
    },
    {
        value: 2,
        label_zh: "大型企业"
    },
    {
        value: 3,
        label_zh: "中型企业"
    },
    {
        value: 4,
        label_zh: "小型企业"
    },
    {
        value: 5,
        label_zh: "微型企业"
    },
]

export const porganizationTypeList = [            //党组织类型
    {
        value: 1,
        label_zh: "党委"
    },
    {
        value: 2,
        label_zh: "总支"
    },
    {
        value: 3,
        label_zh: "支部"
    },
    {
        value: 4,
        label_zh: "联合支部"
    },
]

export const porganizationNatureList = [            //党组织性质
    {
        value: 1,
        label_zh: "街道乡镇"
    },
    {
        value: 2,
        label_zh: "机关"
    },
    {
        value: 3,
        label_zh: "事业单位"
    },
    {
        value: 4,
        label_zh: "公有制经济"
    },
    {
        value: 5,
        label_zh: "非公有制经济"
    },
    {
        value: 6,
        label_zh: "民办非企业事务所"
    },
]

export const characteristicsBusinessList = [            //特色业务
    {
        value: 1,
        label_zh: "化工品"
    },
    {
        value: 2,
        label_zh: "食品"
    },
    {
        value: 3,
        label_zh: "危化品"
    },
    {
        value: 4,
        label_zh: "服装、纺织品"
    },
    {
        value: 5,
        label_zh: "冻品"
    },
    {
        value: 6,
        label_zh: "水产品"
    },
    {
        value: 7,
        label_zh: "化妆品"
    },
    {
        value: 8,
        label_zh: "展览品"
    },
    {
        value: 0,
        label_zh: "其他"
    },
]

export const companyAdvantageList = [            //企业优势
    {
        value: 1,
        label_zh: "自有集卡"
    },
    {
        value: 2,
        label_zh: "仓库保税区（新片区）"
    },
    {
        value: 0,
        label_zh: "其他"
    },
]

export const expectServiceList = [            //期望服务
    {
        value: 1,
        label_zh: "政策宣讲"
    },
    {
        value: 2,
        label_zh: "行业座谈"
    },
    {
        value: 3,
        label_zh: "沙龙论坛"
    },
    {
        value: 4,
        label_zh: "业务咨询"
    },
    {
        value: 5,
        label_zh: "预归类受理"
    },
    {
        value: 6,
        label_zh: "专业培训"
    },
    {
        value: 7,
        label_zh: "定制式培训"
    },
    {
        value: 8,
        label_zh: "人才推荐"
    },
    {
        value: 9,
        label_zh: "法律维权"
    },
    {
        value: 10,
        label_zh: "党建联动"
    },
    {
        value: 11,
        label_zh: "企业宣传"
    },
    {
        value: 12,
        label_zh: "文体活动"
    },
    {
        value: 0,
        label_zh: "其他"
    },
]